import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import backgroundMobile from "./arts/background.png";
import backgroundDesktop from "./arts/backgroundDesktop.png";
import { Uniswap, DexTools, Opensea, Telegram, Twitter } from "./arts/Icons";
import {
  DeckWrapper,
  HeroBtn,
  HeroDescription,
  HeroPicture,
  HeroQuestion,
  HeroSection,
  HeroTitle,
  HeroTitleMobile,
  TextWrapper,
} from "./Style/Hero.styled";
import {
  MenuBtn,
  MenuItem,
  MenuList,
  NavMenu,
  BurgerItem,
  MenuItemBig,
  BurgerList,
} from "./Style/Menu.styled";
import alien from "./arts/TardAlien.png";
import astraunaut from "./arts/TardAstraunat.png";
import moon from "./arts/TardMoon.png";
import superTard from "./arts/SuperTard.png";
import stars from "./arts/stars.svg";
import heliTard from "./arts/helitard.png";
function App() {
  const containerRef = useRef<IParallax>(null);
  // Setting the `from` property to define initial position
  const parallaxRef = useRef<any>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Assuming 768px as the breakpoint
  const [isScrolled, setIsScrolled] = useState(false);
  const [showText, setIsShown] = useState(false);
  const [exitText, setIsExited] = useState(false);
  const [bottomText, setIsBottom] = useState(false);
  const [scrollOffset, setScrollTop] = useState<number>(0);
  interface ParallaxScrollEvent {
    offset: number; // Ensure this is correct based on your findings
  }
  const [{ xy }, setXY] = useSpring(() => ({ xy: [0, 0] })); // Initial position
  const [{ alienxy }, setalienXY] = useSpring(() => ({ alienxy: [0, 0] })); // Initial position
  const [{ alien1xy }, setalienXY1] = useSpring(() => ({ alien1xy: [0, 0] })); // Initial position
  useEffect(() => {
    const container = parallaxRef.current?.container.current;

    if (container) {
      const handleScroll = () => {
        const currentScroll = container.scrollTop;
        // Update isScrolled based on scroll position
        if (isMobile) {
          const xPos = -currentScroll / 2 + 700; // Horizontal movement
          const yPos = currentScroll + 200; // Vertical movement upwards
          setXY({ xy: [xPos, yPos] });
        } else {
          const xPos = -currentScroll + 800; // Horizontal movement
          const yPos = -1000; // Vertical movement upwards
          const xPos1 = -currentScroll - 500; // Horizontal movement
          const yPos1 = 0; // Vertical movement upwards
          const xPos2 = +currentScroll + 100; // Horizontal movement
          const yPos2 = 0; // Vertical movement upwards
          setXY({ xy: [xPos, yPos] });
          setalienXY({ alienxy: [xPos1, yPos1] });
          setalienXY1({ alien1xy: [xPos2, yPos2] });
        }

        setIsScrolled(currentScroll > 1300);
        setIsShown(currentScroll > 800);
        setIsExited(currentScroll > 1200);
        setIsBottom(currentScroll > 2000);
        console.log("Scroll Position:", currentScroll);
      };

      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [setXY]);

  useEffect(() => {
    if (parallaxRef.current) {
      const parallaxContainer = parallaxRef.current.container.current;

      // Disable transitions temporarily
      parallaxContainer.style.transition = "none";

      // Scroll to the desired page without animation
      parallaxRef.current.scrollTo(3);

      // Re-enable transitions after a tick
      setTimeout(() => {
        parallaxContainer.style.transition = "";
      }, 0); // you can adjust timeout to be longer if it visually works better
    }
  }, []);

  const Starstyle = {
    width: "100vw",
    position: "absolute" as "absolute",
    top: "-100px",

    display: "block",
  };
  const alienStyle = {
    width: "200px",

    bottom: "400px",
    display: "block",
  };
  const logoStyleRot = {
    transition: "opacity 1s ease-in-out", // Adjust timing and easing here
    opacity: showText ? 0 : 1, // Fades out when scrolled
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column" as "column",
  };
  const logoStyleOut = {
    transition: "opacity 1s ease-in-out", // Adjust timing and easing here
    opacity: exitText ? (bottomText ? 0 : 1) : 0, // Fades out when scrolled
  };
  const Desktopstyle = {
    width: "100%",

    bottom: "400px",
    display: "block",
  };
  const Mobilestyle = {
    width: "100%",

    bottom: "100px",
    display: "block",
  };
  const Moonstyle = {
    width: "250px",

    display: "block",
  };

  return (
    <div className="background">
      <main>
        <Parallax
          pages={4}
          ref={parallaxRef}
          style={{
            top: "0",
            left: "0",
            backgroundColor: "rgba(120,187,233,0)",
          }}
        >
          <ParallaxLayer
            offset={3} // Start at the bottom when content is reversed
            speed={0.5}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(120,187,233,0)",
              zIndex: 3,
            }}
          >
            <div style={{ zIndex: 15, backgroundColor: "rgba(120,187,233,0)" }}>
              <div
                style={{
                  position: "relative",
                  bottom: "10vw",
                  zIndex: 15,
                  color: "#2F4D24",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(120,187,233,1)",
                }}
              >
                {isMobile ? (
                  <img
                    src={backgroundMobile}
                    alt="Mobile Background"
                    style={Desktopstyle}
                  />
                ) : (
                  <img
                    src={backgroundDesktop}
                    alt="Desktop Background"
                    style={Mobilestyle}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    zIndex: 20,
                    bottom: "100px",
                  }}
                  className="updown"
                >
                  <HeroTitleMobile>UpOnli↑</HeroTitleMobile>
                </div>
              </div>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={0} // Start at the bottom when content is reversed
            speed={-0.8}
            factor={1.5}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              top: "-350px",

              alignItems: "center",
              zIndex: 40,
              color: "#FCFCFC",
              backgroundColor: "none",
            }}
          >
            <div style={logoStyleRot}>
              {" "}
              {isMobile ? (
                <HeroTitleMobile>$TARDO</HeroTitleMobile>
              ) : (
                <HeroTitle>$TARDO</HeroTitle>
              )}
              <HeroDescription>
                Apu's cute and special lil cusin
              </HeroDescription>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  marginTop: "20px",
                }}
              >
                <MenuItem>
                  <a
                    href="https://t.me/TardoCoin"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    aria-label="a link to the Telegram"
                  >
                    <Telegram />
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://x.com/TardosCoin"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    aria-label="a link to the Twitter page"
                  >
                    <Twitter />
                  </a>
                </MenuItem>
                <MenuItemBig>
                  <a
                    href="https://app.uniswap.org/swap?outputCurrency=0x42069401135E0b750E737d1C0C0DBa8bE273a41e&chain=ethereum"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    aria-label="a link to the Uniswap"
                  >
                    <Uniswap />
                  </a>
                </MenuItemBig>
                <MenuItemBig>
                  <a
                    href="https://www.dextools.io/app/en/ether/pair-explorer/0x63e260a5c670afd752a0f3c7a2ec9b7c4b1fc6df?t=1730148125649"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    aria-label="a link to the Uniswap"
                  >
                    <DexTools />
                  </a>
                </MenuItemBig>
              </div>
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={2} // Start at the bottom when content is reversed
            speed={-0.8}
            factor={1.5}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              top: "-200px",

              alignItems: "center",

              color: "#FCFCFC",
              backgroundColor: "none",
              zIndex: 31,
            }}
          >
            <div style={logoStyleOut}>
              {" "}
              {isMobile ? (
                <div
                  className="cloud-text"
                  style={{ fontSize: "39px", fontFamily: "Cloud2" }}
                >
                  A new Fren as Arrived!
                </div>
              ) : (
                <div>
                  <div
                    className="cloud-text"
                    style={{
                      fontSize: "69px",
                      fontFamily: "Cloud2",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    A new Fren as Arrived!
                  </div>
                  <div
                    className="cloud-text"
                    style={{
                      fontSize: "29px",
                      textAlign: "center",
                      fontFamily: "Cloud2",
                      marginTop: "10px",
                    }}
                  >
                    <a style={{ color: "#5E8E3E" }}>Tardo</a> is
                    <a style={{ color: "#5E8E3E" }}> Apu's </a>little retarded
                    brother
                  </div>
                  <div
                    className="cloud-text"
                    style={{
                      fontSize: "29px",
                      textAlign: "center",
                      fontFamily: "Cloud2",
                    }}
                  >
                    and he is gonna take you on a journey
                  </div>

                  <div
                    className="cloud-text"
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      fontFamily: "Cloud2",
                      marginTop: "30px",
                    }}
                  >
                    {" "}
                    CA: 0x42069401135E0b750E737d1C0C0DBa8bE273a41e
                  </div>
                </div>
              )}
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={0.5}
            factor={1}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "1800px",
              alignItems: "center",
              bottom: "30vh",
              backgroundColor: "rgba(120,187,233,1)",
              zIndex: 0,
            }}
          ></ParallaxLayer>
          <ParallaxLayer
            offset={1}
            speed={0.5}
            factor={2}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "900px",
              alignItems: "center",
              top: "40vh",
              backgroundImage:
                "linear-gradient(0deg, rgba(120,187,233,1) 0%, rgba(70,104,132,1) 50%, rgba(21, 22, 31, 1) 100%)",
            }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={0} // Top of the container
            speed={0.5}
            style={{
              display: "flex",

              justifyContent: "center",
              alignItems: "center",
              height: "2000px",
              top: "-360px",
              zIndex: -5,
              backgroundColor: "#15161F",
            }}
          >
            {" "}
            <img src={stars} alt="Mobile Background" style={Starstyle} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={0.15} // Start at the bottom when content is reversed
            speed={0.9}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              top: "-369px",
              left: "470px",
              alignItems: "center",
              zIndex: 5,

              // backgroundColor: "rgba(120,187,233,1)",
            }}
          >
            {" "}
            <img src={moon} alt="Mobile Background" style={Moonstyle} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={0.4} // Start at the bottom when content is reversed
            speed={0.6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              top: "-269px",
              left: "300px",
              alignItems: "center",
              zIndex: 5,

              backgroundColor: "none",
            }}
          >
            <animated.div
              style={{
                transform: alien1xy.to(
                  (x, y) => `translate3d(${x}px, ${y}px, 0)`
                ),
                willChange: "transform",
              }}
            >
              {" "}
              <img
                src={alien}
                className="wobble-effect1"
                alt="Mobile Background"
                style={alienStyle}
              />
            </animated.div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={0.4} // Start at the bottom when content is reversed
            speed={0.6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              top: "-269px",
              right: "300px",
              alignItems: "center",
              zIndex: 5,

              backgroundColor: "none",
            }}
          >
            <animated.div
              style={{
                transform: alienxy.to(
                  (x, y) => `translate3d(${x}px, ${y}px, 0)`
                ),
                willChange: "transform",
              }}
            >
              {" "}
              <img
                src={astraunaut}
                className="wobble-effect"
                alt="Mobile Background"
                style={alienStyle}
              />
            </animated.div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={3}
            speed={-0.3}
            style={{
              display: "flex",
              zIndex: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <animated.div
              style={{
                transform: xy.to((x, y) => `translate3d(${x}px, ${y}px, 0)`),
                willChange: "transform",
              }}
            >
              <img
                src={superTard}
                alt="Diagonal Moving Image"
                className="wobble-effect"
                style={{ width: "350px", height: "auto" }}
              />
            </animated.div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={4}
            speed={-0.3}
            style={{
              display: "flex",
              zIndex: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <animated.div
              style={{
                transform: xy.to(
                  (x, y) => `translate3d(${-x / 2}px, ${y}px, 0)`
                ),
                willChange: "transform",
              }}
            >
              <img
                src={heliTard}
                alt="Diagonal Moving Image"
                className="wobble-effect"
                style={{ width: "300px", height: "auto" }}
              />
            </animated.div>
          </ParallaxLayer>
        </Parallax>
      </main>
    </div>
  );
}

export default App;
